import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./nvbutton.css"
import "./mintbutton.css"
import "./footer.css"
import "./navlinks.css"
import "./contractlink.css"
import "./metamobile.css"
import Plurthem from "./components/plurthem/Plurthem";
import Home from "./components/home/home";
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import About from "./components/about/About";
import Soren from "./components/soren/soren";
import Warehouse from "./components/Warehouse/Warehouse";
import Electrik from "./components/Electrik/Electrik";














const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;





function App() {
  return(


      <BrowserRouter>
          <Routes basename='/index.html'>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/Plurthem" element={<Plurthem />} />
            <Route exact path="/soren" element={<Soren />} />
            <Route exact path="/warehouse" element={<Warehouse />} />
            <Route exact path="/Electrik" element={<Electrik />} />
            
       

          </Routes>
      </BrowserRouter>

  )


}

export default App;
