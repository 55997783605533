import React, { useEffect, useState } from "react";
import "./NFTCard.css";
import { FaEthereum } from "react-icons/fa";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { ColorExtractor } from 'react-color-extractor'
import Card from "./Card";
import Button from "./Button";
import { Colors } from "./Colors";
import WH from "./whc3.jpg"
import PLUR from "./plurfav.png"
import { useNavigate} from "react-router-dom"



import { ModelViewerElement } from "@google/model-viewer";




const WarehouseNFTCard = ({ username, nftName, price, nftSrc, likeCount, gradient, onClick }) => {
  const [isLike, setIsLike] = useState(false);
  const [colors, setColors] = useState([]);


  const like = () => setIsLike(!isLike);

  const getColors = colors => {
    setColors(c => [...c, ...colors]);
    //console.log(colors);
  }

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/Warehouse`; 
    navigate(path);
  }



  return (
    <Card onClick={routeChange}
      blurColor={colors[0]}

      child={<>
        <model-viewer ar-scale="auto" ar ar-modes="webxr scene-viewer quick-look" id="reveal" loading="eager" camera-controls auto-rotate src={nftSrc} > </model-viewer> : <><ColorExtractor getColors={getColors}>
          <img className="nft-image" src={WH} type= "video/mp4" />
        </ColorExtractor></>
        <div className="wrapper">
          <div className="info-container">
            <p className="owner"> PC Samples</p>
            <p className="name">Warehouse</p>
          </div>

          <div className="price-container">
            <p className="price-label">Price</p>
            <p className="price">
              {" "}
              <img style ={{
                height: "20px",
                width: "20px"
              }} src={PLUR} /> 100k
            </p>
          </div>
        </div>
        <div className="buttons">
          {/* <button className="buy-now">Buy Now</button> */}
          <Button color={Colors.buttons.primary} textContent="Mint Now" onClick={routeChange} /> 
          <div className="like-container">
            <button className="like" onClick={like}>
              {!isLike ? (
                <AiOutlineHeart size="30" color="white" />
              ) : (
                <AiFillHeart size="30" style={{
                  stroke: `-webkit-linear-gradient(
                    to bottom,
                    #d00087,
                    #d00090
                  );`
                }} color='#d00087' />
              )}
            </button>
            <p className="like-count">0</p>
          </div>
        </div>
      </>}>

    </Card>
  );
};

export default WarehouseNFTCard;