import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "./globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap"
import "./nvbutton.css"
import "./mintbutton.css"
import "./footer.css"
import "./navlinks.css"
import "./contractlink.css"
import Header from "../navbar/navbar";
import Footer from "../footer/footer"






const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: light;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledRoundButton = styled.button`
  color: blue;
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 80%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoText = styled.img`
  display: block;
  max-width:200px;
  max-height:80px;
  width: auto;
  height: auto;
`;


export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 0px solid var(--secondary);
  background-color: #0a61b2;
  border-radius: 5%;
  width: 400px;
  resizeMode: 'contain',
  @media (min-width: 1000px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 490px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  hover: white;
`;

export const StyledLink2 = styled.a`
  color: var(--secondary);
  text-decoration: none;
  hover: white;
`;


export const StyledIcon = styled.a`
  text-decoration: none;
`;



function About() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Mint your PLTH`);
  const [tokens, settokens] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    PLUR_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_PLUR: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });


  const PLURMINT = () => {
    let cost = CONFIG.PLUR_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    blockchain.smartToken.methods
    .balanceOf(blockchain.account)
    .call()
    .then((receipt) => {
      let balance = receipt;
      if (balance < price) {
        setFeedback(
          `you dont have enough Plur to Mint.`
        );
        setClaimingNft(false);
      }
      else {
        blockchain.smartToken.methods
        .approve(CONFIG.CONTRACT_ADDRESS, price)
        .send({
          to: CONFIG.TOKEN,
          from: blockchain.account,
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Approved`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
        blockchain.smartContract.methods
          .mintPLUR(tokens)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
      }
    });
   
  };



  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > 50) {
      newtokens = 50;
    }
    settokens(newtokens);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/configplur/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  return (
            
    <s.Screen>  
     <Header />
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#0760b5" }}
        image={CONFIG.SHOW_BACKGROUND ? "/configplur/images/bg.png" : null}
      >
        <ResponsiveWrapper flex={2} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <s.TextTitle
              style={{
          
                fontFamily: "tank",
                color: "white",
                backgroundColor: "transparent",
                textAlign: "center",
                fontSize: 60,
                fontWeight: "bold",
                whiteSpace: "normal",
                border: "2px, solid #fff",
                borderColor: "white"

              }}
            >
              ABOUT  
            </s.TextTitle>

            <s.SpacerMedium />

            <s.SpacerMedium />
            <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)",              
                    
                  
                    color: "white",
                    fontSize: "20px",
                    lineHeight: "2em",
                    textAlign: "center",
                    whiteSpace: "normal"
                  }} 
                >
                  
                  PLURable is the world's first Dance Music record label living solely on the Blockchain. PLURable features rare, limited-edition drops from artists within the Electronic Dance Music sphere. Each collection is capped to a limited number with every NFT having a distinct token ID, rendering it truly unique. Once minted, the NFTs can be stored in a digital wallet, staked to earn additional rewards, used to unlock hidden features and be sold on NFT marketplaces.  PLURable operates exclusively with PLURcoin. In order to mint these rare music NFTs, you must be a PLURcoin holder.
                </s.TextDescription>
            

            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ fontFamily: "Organic Relief", textAlign: "center", color: "var(--accent-text)" }}
                >
                  SOLD OUT.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
  
  

                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton classsName='rounded'
                        style={{                       
                        fontFamily: "Organic Relief",
                        color: "#d00087", lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementtokens();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {tokens}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton classsName='rounded'             
                      style={{
                      fontFamily: "Organic Relief",
                      color: "#d00087"
            }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementtokens();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <Button className="mintButton"  variant="Primary"
                                  style={{
                                    fontFamily: "Organic Relief",
                                  }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    PLURMINT();
                                    getData();
                                  }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </Button>
                    </s.Container>
                  </>
                )}
              </>
            )}
                    <s.SpacerMedium />
                    <s.TextTitle
              style={{
                padding: "10px 200px 10px",
                fontFamily: "Organic Relief",
                color: "white",
                fontSize: "20px",
                lineHeight: "3em",
                textAlign: "center",
                whiteSpace: "normal"
              }}
            >
                
            </s.TextTitle>
            <s.TextTitle
              style={{
                padding: "5px 200px 10px",
                fontFamily: "",
                color: "white",
                fontSize: "10px",
                lineHeight: "3em",
                textAlign: "center",
                whiteSpace: "normal"
              }}
            >
              Terms & Conditions apply     
            </s.TextTitle>
          </s.Container>

          </ResponsiveWrapper>
          <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
      </s.Container>
      </s.Container>

      
      <Footer />

    </s.Screen>
    
  );
}

export default  About;
