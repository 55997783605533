import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse
} from 'mdb-react-ui-kit';
import "./nvbutton.css"
import "./mintbutton.css"
import "./footer.css"
import "./navlinks.css"
import "./contractlink.css"
import "./toggler.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import ICON from "./Icon2.png"
import HAM from "./ham4.png"
import { useNavigate} from "react-router-dom"


export default function Header() {
  const [showNav, setShowNav] = useState(false);


  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/About`; 
    navigate(path);
  }


  return (
    <MDBNavbar expand='lg' dark href='#' style={{
      backgroundColor: "#1b131a",
      color: "white",
      padding: "20px"
    }}>
      <MDBContainer fluid>
        <MDBNavbarBrand className='navbarToggler'style={{color: "white", fontFamily: "tank", fontSize: "12px"}} href='#'>           <img
              src={ICON}
              height='40'
              color='white'
              alt=''
              loading='lazy'
            /></MDBNavbarBrand>
        <MDBNavbarToggler className="icon" 
          
          type='button'
          aria-expanded='tue'
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
        >
          <img src={HAM} height='25'
              color='white'
              alt=''
              loading='lazy' style={{color: "white"}} icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse style={{color: "white"}} navbar show={showNav}>
          <MDBNavbarNav>
            <MDBNavbarItem>
              <MDBNavbarLink className='navLinks' style={{ color: "white", fontFamily: "tank", fontSize: "18px"}}                
                   active aria-current='page' href='#'
                  >
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className='navLinks' style={{ cursor: "pointer",color: "white", fontFamily: "tank", fontSize: "18px"}} href onClick={routeChange}>About</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className='navLinks' style={{color: "white",fontFamily: "tank", fontSize: "18px"}} target = {"_blank"} href='https://www.plurcoin.com/'>Buy Plur</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}