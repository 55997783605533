import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import { icons } from "react-icons";
import {FaTwitter, FaFacebook, FaInstagram, FaDiscord} from "react-icons/fa"
import {IconContext} from "react-icons"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap"
import "./nvbutton.css"
import "./mintbutton.css"
import "./footer.css"
import "./navlinks.css"
import "./contractlink.css"
import "./metamobile.css"
import PLURvid from "./plurvid.MP4"
import PLURvid2 from "./plurvid2.mp4"
import Header from "../navbar/navbar";
import Footer from "../footer/footer"






const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: light;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledRoundButton = styled.button`
  color: blue;
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 80%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoText = styled.img`
  display: block;
  max-width:200px;
  max-height:80px;
  width: auto;
  height: auto;
`;


export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 0px solid var(--secondary);
  background-color: #0a61b2;
  border-radius: 5%;
  width: 400px;
  resizeMode: 'contain',
  @media (min-width: 1000px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 490px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  hover: white;
`;

export const StyledLink2 = styled.a`
  color: var(--secondary);
  text-decoration: none;
  hover: white;
`;


export const StyledIcon = styled.a`
  text-decoration: none;
`;



function Plurthem() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Mint your PLTH`);
  const [tokens, settokens] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    PLUR_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_PLUR: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });


  const PLURMINT = () => {
    let cost = CONFIG.PLUR_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    blockchain.smartToken.methods
    .balanceOf(blockchain.account)
    .call()
    .then((receipt) => {
      let balance = receipt;
      if (balance < price) {
        setFeedback(
          `you dont have enough Plur to Mint.`
        );
        setClaimingNft(false);
      }
      else {
        blockchain.smartToken.methods
        .approve(CONFIG.CONTRACT_ADDRESS, price)
        .send({
          to: CONFIG.TOKEN,
          from: blockchain.account,
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Approved`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
        blockchain.smartContract.methods
          .mintPLUR(tokens)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `WOW, the ${CONFIG.NFT_NAME} is yours! go visit OpenSea to view it.` 
              
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
      }
    });
   
  };



  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > 50) {
      newtokens = 50;
    }
    settokens(newtokens);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/configplur/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  return (
            
    <s.Screen>  
     <Header />
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 12, backgroundColor: "#0760b5" }}
        image={CONFIG.SHOW_BACKGROUND ? "/configplur/images/bg.png" : null}
      >
        <ResponsiveWrapper flex={2} style={{ padding: 12 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          <s.TextTitle
              style={{
          
                fontFamily: "tank",
                color: "white",
                backgroundColor: "transparent",
                textAlign: "center",
                fontSize: 60,
                fontWeight: "bold",
                whiteSpace: "normal",
                border: "2px, solid #fff",
                borderColor: "white"

              }}
            >
              PLURTHEM  
            </s.TextTitle>

            <s.SpacerMedium />
            <div>
            <video   
                      muted={false}
                      autoPlay={"autoplay"}
                      preLoad="auto"
                      loop  
                      controls
                      
            style={{
              position: "centre",
              height: "500px",
              width: "500px",
              objectFit: "cover",
              borderRadius: "10%",
              maxHeight: "100%",
              maxWidth: "100%"
              
            }}>
              <source src={PLURvid2} type= "video/mp4" />
            </video>
            </div>
            <s.SpacerMedium />

            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >

            </s.TextDescription>
            <s.TextTitle
                  style={{ fontFamily: "tank",textAlign: "center", color: "var(--accent-text)" }}
                >
                     Price: {CONFIG.PLUR_COST} PLUR
                </s.TextTitle>
            <s.SpacerSmall />

            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              
              <>
                <s.TextTitle
                  style={{ fontFamily: "tank", textAlign: "center", color: "var(--accent-text)" }}
                >
                  SOLD OUT.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
  
                    <Button className="mintButton"  variant="Primary"
                                style={{
                                fontFamily: "tank",
                                fontSize: "20px"
                                }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </Button>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton classsName='rounded'
                        style={{                       
                        fontFamily: "tank",
                        color: "#d00087", lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementtokens();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {tokens}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton classsName='rounded'             
                      style={{
                      fontFamily: "tank",
                      color: "#d00087"
            }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementtokens();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <Button className="mintButton"  variant="Primary"
                                  style={{
                                    fontFamily: "tank",
                                  }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    PLURMINT();
                                    getData();
                                  }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </Button>
                    </s.Container>
                  </>
                )}
              </>
            )}
                    <s.SpacerMedium />
                    <s.TextTitle
              style={{
                padding: "10px 200px 10px",
                fontFamily: "tank",
                color: "white",
                fontSize: "40px",
                lineHeight: "1.5em",
                textAlign: "center",
                whiteSpace: "normal"
              }}
            >
              THE FIRST RELEASE ON PLURABLE COMES FROM TECHNO DUO ZEUS!   
            </s.TextTitle>
            <s.TextTitle
              style={{

                fontFamily: "",
                
                color: "white",
                fontSize: "10px",
                lineHeight: "2.5em",
                textAlign: "center",
                whiteSpace: "normal"
              }}
            >
              Only 100 copies of PLURTHEM will ever exsist. Connect your wallet to the ethereum blockchain and mint your copy.    
            </s.TextTitle>
            
          </s.Container>

          </ResponsiveWrapper>
          <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
      </s.Container>
      </s.Container>

      
      <Footer />

    </s.Screen>
    
  );
}

export default  Plurthem;
